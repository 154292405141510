import React from "react";
import Navbar from "./components/navbar";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import Footer from "./components/footer";
import Content from "./components/content";
import Login from "./components/login";
import useToken from "./components/useToken";
import { Route, Switch } from "react-router-dom";

const App = () => {
  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} />;
  }

  return (
    <div>
      <Sidebar />
      <div class="main-content" id="panel">
        <Navbar />
        <Header />
        <div class="container-fluid mt--6">
          <Content />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
