import React from "react";
import { Oval } from "react-loader-spinner";
const Loader = ({ loading }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Oval
        height="28"
        width="28"
        radius="3"
        color="#1890FF"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={loading}
      />
    </div>
  );
};

export default Loader;
