import React, { useContext, useState } from "react";
import { xContext } from "./UserContext";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const user = useContext(xContext);
  const history = useHistory();
  const handleClick = () => {
    //navigate to topup page
    history.push("/topup");
    console.log("Button clicked!");
  };

  return (
    <nav className="navbar navbar-top navbar-expand navbar-dark bg-gradient-info border-bottom">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <form
            className="navbar-search navbar-search-light form-inline mr-sm-3"
            id="navbar-search-main"
          >
            <div className="form-group mb-0">
              <div className="input-group input-group-alternative input-group-merge mr-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-calendar-alt"></i>
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Start Date"
                  type="text"
                  onFocus={(e) => {
                    e.target.type = "date";
                    e.target.placeholder = "";
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                    e.target.placeholder = "Start Date";
                  }}
                ></input>
              </div>

              <div className="input-group input-group-alternative input-group-merge mr-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-calendar-alt"></i>
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="End Date"
                  type="text"
                  onFocus={(e) => {
                    e.target.type = "date";
                    e.target.placeholder = "";
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                    e.target.placeholder = "End Date";
                  }}
                ></input>
              </div>

              <button type="button" className="btn btn-default">
                <i className="fas fa-filter"></i>
              </button>
            </div>
          </form>

          <ul className="navbar-nav align-items-center  ml-md-auto ">
            <li className="nav-item d-xl-none">
              <div
                className="pr-3 sidenav-toggler sidenav-toggler-dark"
                data-action="sidenav-pin"
                data-target="#sidenav-main"
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
            <li className="nav-item d-sm-none">
              <Link
                className="nav-link"
                data-action="search-show"
                data-target="#navbar-search-main"
              >
                <i className="ni ni-zoom-split-in"></i>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/topup" className="btn btn-default">
                <span>CREDIT</span>
                <span className="badge badge-primary">{user.balance}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
