import React, { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { xContext } from "./UserContext";
const Whatsapp = () => {
  const [title, setTitle] = useState("");
  const [mtitle, setMtitle] = useState("");
  const [phone, setPhone] = useState("260******");
  const [sender, setSender] = useState("");
  const [message, setMessage] = useState("");
  const [mmessage, setmMessage] = useState("");
  const [maxlength] = useState(640);
  const [newlength, setNewLength] = useState();
  const [mnewlength, setmNewLength] = useState();
  const ctx = useContext(xContext);

  const myupdate = () => {
    ctx.updateUser();
  };

  const changeLength = (e) => {
    setMessage(e.target.value);
    let newl = maxlength - e.target.value.length;
    setNewLength(newl);
  };

  const changemLength = (e) => {
    setmMessage(e.target.value);
    let newl = maxlength - e.target.value.length;
    setmNewLength(newl);
  };

  const handlemSubmit = (e) => {
    e.preventDefault();

    axios
      .get(process.env.REACT_APP_SERVER + "/checkBalance")
      .then((response) => {
        if (response.data > ctx.balance) {
          Swal.fire({
            title: "Failed",
            text: "Insufficient credit",
            type: "error",
          });
        } else {
          axios
            .post(process.env.REACT_APP_SERVER + "/bulk", {
              title: mtitle,
              type: 0,
              dir: 1,
              source: sender,
              message: mmessage,
              username: "chobela12",
              password: "Theres@1",
            })
            .then((res) => {
              Swal.fire({
                title: "Sent",
                text: "Messages Sent",
                type: "success",
              });
              setMtitle("");
              setmMessage("");
              myupdate();
            })
            .catch((err) => {
              Swal.fire({
                title: "Oops!",
                text: "Something went wrong",
                type: "error",
              });

              setMtitle("");
              setmMessage("");
            });

          Swal.fire({
            title: "Sending",
            text: "Sending Messages",
            type: "success",
          });

          setMtitle("");
          setmMessage("");
          myupdate();
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = new URLSearchParams();
      data.append("token", "wjol3uk0u3folb64");
      data.append("to", phone); // Specify the recipient here
      data.append("body", `*${sender}* : ` + " " + message);

      const config = {
        method: "post",
        url: `https://api.ultramsg.com/instance59746/messages/chat`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      const response = await axios(config);
      console.log(JSON.stringify(response.data));
      // setTextAreaValue("");
      //setLoading(false);
    } catch (error) {
      console.error(error);
      //setLoading(false);
    }
  };

  return (
    <div className="container-fluid mt--6">
      <div className="row">
        <div className="col-xl-6">
          <div>
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h3 className="mb-0">Bulk Whatsapp</h3>
                  </div>
                  <div className="col-4 text-right"></div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={""}>
                  <h6 className="heading-small text-muted mb-4">
                    To All contacts
                  </h6>
                  <div className="pl-lg-12">
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="form-control-label"
                            for="input-username"
                          >
                            Sender ID
                          </label>
                          <select
                            value={sender}
                            onChange={(e) => setSender(e.target.value)}
                            type="text"
                            className="form-control js-example-basic-single"
                          >
                            <option value="">-- Select Sender ID --</option>
                            <option value="PICKME">PICKME</option>
                            <option value="ANGONI">ANGONI</option>
                            <option value="KSM">KSM</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label className="form-control-label">Message</label>
                          <textarea
                            rows="4"
                            className="form-control"
                            autocomplete="off"
                            placeholder=""
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-footer">
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-primary float-right"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div>
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h3 className="mb-0">Single Whatsapp</h3>
                  </div>
                  <div className="col-4 text-right"></div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    To Single Whatsapp contact
                  </h6>
                  <div className="pl-lg-12">
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="form-control-label"
                            for="input-username"
                          >
                            Sender ID
                          </label>
                          <select
                            value={sender}
                            onChange={(e) => setSender(e.target.value)}
                            type="text"
                            className="form-control js-example-basic-single"
                          >
                            <option value="">-- Select Sender ID --</option>
                            <option value="PICKME">PICKME</option>
                            <option value="ANGONI">ANGONI</option>
                            <option value="KSM">KSM</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="form-control-label"
                            for="input-username"
                          >
                            Number
                          </label>
                          <input
                            type="number"
                            id="number"
                            className="form-control"
                            placeholder={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            autocomplete="off"
                            value={phone}
                            required
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label className="form-control-label">Message</label>
                          <textarea
                            rows="4"
                            className="form-control"
                            onChange={(e) => changeLength(e)}
                            maxlength={maxlength}
                            value={message}
                            autocomplete="off"
                            placeholder=""
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-footer">
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-primary float-right"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whatsapp;
