import React, { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { xContext } from "./UserContext";
import Loader from "./loader";

const Topup = () => {
  const [phone, setPhone] = useState("0******");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const ctx = useContext(xContext);

  const myupdate = () => {
    ctx.updateUser();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post(process.env.REACT_APP_SERVER + "/topup", {
        phone: phone,
        amount: amount,
      })
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          console.log(response.data); // Logs the response data from the server
          Swal.fire({
            title: "Success",
            text: "Credit Added Successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
          setPhone("");
          setAmount("");
          myupdate(); // Update UI or fetch new data as necessary
        } else {
          // Handle any other status codes if necessary
          Swal.fire({
            title: "Failed",
            text: "Credit Topup Failed",
            icon: "error",
            confirmButtonText: "Ok",
          });
          setPhone("");
          setAmount("");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(
          "Error:",
          error.response ? error.response.data : error.message
        );
        let errorMessage = "Network Error";
        if (error.response) {
          // You can handle specific status codes if you wish
          switch (error.response.status) {
            case 401: // Unauthorized
            case 403: // Forbidden
            case 404: // Not Found, etc.
              errorMessage = error.response.data.message || "An error occurred";
              break;
            default:
              errorMessage = error.response.data.message || "Server Error";
              break;
          }
        }
        Swal.fire({
          title: "Oops!",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div className="container-fluid mt--6">
      <div className="row">
        <div className="col-xl-6">
          <div>
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h3 className="mb-0">Topup</h3>
                  </div>
                  <div className="col-4 text-right"></div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Topup with Mobile Money (Airtel | MTN | ZAMTEL)
                  </h6>
                  <div className="pl-lg-12">
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label className="form-control-label" for="amount">
                            SMS Bundle
                          </label>
                          <select
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            type="text"
                            className="form-control"
                          >
                            <option value="0">-- Select --</option>
                            <option value="125">K125 - (250 SMS)</option>
                            <option value="175">K175 - (450 SMS)</option>
                            <option value="275">K275 - (650 SMS)</option>
                            <option value="375">K375 - (1000 SMS)</option>
                            <option value="525">k525 - (1500 SMS)</option>
                            <option value="925">K925 - (3000 SMS)</option>
                            <option value="2125">K2125 - (8000 SMS)</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="form-control-label"
                            for="input-username"
                          >
                            Mobile Money Number (Starting with 0)
                          </label>
                          <input
                            type="number"
                            id="number"
                            className="form-control"
                            placeholder={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            autocomplete="off"
                            value={phone}
                            required
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-footer">
                    <Loader loading={loading} />
                    <button
                      type="submit"
                      className="btn btn-primary float-right"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topup;
